import { ready } from 'brei-project-utils';
import { tabs } from './modules/tabs';

const home = {

	elem: {
		main: document.querySelector('.main.home'),
		sections: document.querySelectorAll('.main > section')
	},

	observeAnimate: null,
	
	init() {

		tabs.init();
		
		// If the system supports the IntersectionObserver API, create
		// a new Intersection Observer; store each section's
		// background color in a data-color attribute, add the class
		// .animationStart, and set its background to transparent;
		// then add the section to the observer.
		
		if(!!window.IntersectionObserver){
			home.observeAnimate = new IntersectionObserver(home.animate, { rootMargin: '-120px', threshold: 0 });
			this.elem.sections.forEach(function(element) {
				element.dataset.color = window.getComputedStyle(element).backgroundColor;
				element.classList.add('animationStart');
				element.style.backgroundColor = 'transparent';
				home.observeAnimate.observe(element);
			});
		}

		// Trigger colorize function on scroll or resize
		$(window).on('load scroll resize', function() {
			home.colorize();
		});
			
	},

	// When a section becomes visible in the viewport, add the
	// animationEnd class after a 10ms delay and remove it from the
	// observer.
	
	animate(entries) {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				setTimeout(
					function(entry) {
						entry.target.classList.add('animationEnd');
						home.observeAnimate.unobserve(entry.target);
					}, 
					10,
					entry
				); 
			}
		});
	},

	// When a section scrollsa cross the bottom of the screen, change
	// the global background olor to the appropriate value. We have
	// removed this from the IntersectionObserver because its
	// asynchronous nature makes it possible to trick.

	colorize() {
		let viewportBottom = $(window).scrollTop() + window.innerHeight - 120;
		home.elem.sections.forEach(section => {
			if($(section).offset().top <= viewportBottom) {
				home.elem.main.style.backgroundColor = section.dataset.color;
			}
		});
	}	
};

ready(function () {
	home.init();
});
