/**
 * Tabs component.
 * @module components/tabs
 */

import { Tabs } from 'foundation-sites';

const tabs = {

	$window: $(window),

	elem: {
		$panels: $('.tabs-content'),
		$tabs: $('.tabs')
	},

	init() {
		if ( this.elem.$tabs.length > 0) {
			this.elem.$tabs.each(function() {
				this.tab = new Tabs($(this), { deepLink: true });
			});

			this.elem.$tabs.on('change.zf.tabs', function() {
				tabs.elem.$tabs.scrollLeft(0);
				tabs.elem.$tabs.scrollLeft(tabs.elem.$tabs.find('.tabs-title.is-active').position().left);
				tabs.elem.$panels.find('.tabs-panel.is-active')[0].scrollIntoView({ behavior: 'smooth' });
			});
			
		}
	}

};

export { tabs };
